import React from 'react';
import "../styles/index.css"

function MercadoLibro() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Mercado Libro Project</h1>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div id="carouselExample" className="carousel slide" data-bs-theme="dark">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="./assets/MercadoLibro/MercadoLibro.png"
                                            className="d-block w-100 border-frame" alt="Home" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./assets/MercadoLibro/ML1.png" className="d-block w-100 border-frame"
                                            alt="About" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./assets/MercadoLibro/ML2.png" className="d-block w-100 border-frame"
                                            alt="Products" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./assets/MercadoLibro/ML3.png" className="d-block w-100 border-frame"
                                            alt="Contact" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./assets/MercadoLibro/ML4.png" className="d-block w-100 border-frame"
                                            alt="Login" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}

export default MercadoLibro;
