import React, { useState } from 'react';

function Login({ isLoggedIn, setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        // check if credentials are correct
        if (username === 'admin' && password === 'password') {
            setIsLoggedIn(true); // set the authentication state as true
            setMessage('Successful login'); // set login message
        } else {
            setError('Wrong credentials');
        }
    };

    const handleLogout = () => {
        setIsLoggedIn(false); // set the authentication state as false
        setUsername(''); // clear username
        setPassword(''); // clear password
        setMessage('Successful logout'); // set logout message
    };

    return (
        <div>
            <main>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-1 border-bottom">
                    <h1 className="h2">Login</h1>
                </div>
                {/* success or error message */}
                {(message || error) && <div className={`alert ${message ? 'alert-success' : 'alert-danger'}`}>{message || error}</div>}
                {/* Login form */}
                <br />
                <div className="card mx-auto" style={{ width: '18rem' }}>
                    <img src="./assets/door.png" className="img-fluid rounded-start w-100 h-100" alt="Login" />
                    <div className="card-body">
                        <form onSubmit={handleLogin}>
                            <input className="m-2 p-1" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <input className="m-2 p-1" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <hr />
                            <button type="submit" className="btn btn-secondary mx-4">Login</button>
                            {isLoggedIn && <button type="button" className="btn btn-danger mx-3" onClick={handleLogout}>Logout</button>}
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Login;
