import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../styles/index.css";

function Education() {
    const [educations, setEducations] = useState([]);
    const [certifications, setCertifications] = useState([]);

    useEffect(() => {
        axios.get('https://portfolio-ijn-backend.onrender.com/api/education/')
            //axios.get('http://localhost:8000/api/education/')
            .then(response => {
                setEducations(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the education data!", error);
            });

        axios.get('https://portfolio-ijn-backend.onrender.com/api/other-certifications/')
            //axios.get('http://localhost:8000/api/other-certifications/')
            .then(response => {
                setCertifications(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the certifications data!", error);
            });
    }, []);

    return (
        <div>
            <main>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Education</h1>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        {educations.map(education => (
                            <div key={education.id} className="col-md-6 col-lg-6 mb-4">
                                <div className="card">
                                    <img src={education.certificate_image} className="card-img-top" alt={education.degree} />
                                    <div className="card-body">
                                        <h5 className="card-title">{education.institution}</h5>
                                        <p className="card-text">{education.degree}</p>
                                        <p className="card-text">
                                            {new Date(education.start_date).toLocaleDateString()} -
                                            {education.end_date ? new Date(education.end_date).toLocaleDateString() : "Present"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Other Certifications</h1>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        {certifications.map(certification => (
                            <div key={certification.id} className="col-md-6 col-lg-6 mb-4">
                                <div className="card">
                                    <img src={certification.certificate_image} className="card-img-top" alt={certification.name} />
                                    <div className="card-body">
                                        <h5 className="card-title">{certification.name}</h5>
                                        <p className="card-text">{certification.issuing_organization}</p>
                                        <p className="card-text">
                                            Issued on: {new Date(certification.issue_date).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Education;
