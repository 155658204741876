import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/index.css"

function Home() {
  return (
    <div>
      {/* Content container */}
      <main>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Ivette Jael Nobiltá</h1>
        </div>
        {/* Circles about, education and portfolio */}
        <div className="container marketing mt-4">
          <br />
          <div className="row text-center">
            <div className="col-lg-4">
              <Link to="/about">
                <img className="bd-placeholder-img rounded-circle" width="200" height="200" src="../assets/aboutMe.png" alt="About me section"></img>
              </Link>
              <h2 className="fw-normal">About me</h2>
              <p>In this section you can read some information about my background</p>
              <p><Link className="btn btn-dark" to="/about">More information</Link></p>
            </div>
            <div className="col-lg-4">
              <Link to="/portfolio">
                <img className="bd-placeholder-img rounded-circle" width="200" height="200" src="../assets/portfolio.png" alt="Portfolio section"></img>
              </Link>
              <h2 className="fw-normal">Portfolio</h2>
              <p>In this section you can navigate through the projects I've worked on</p>
              <p><Link className="btn btn-dark" to="/portfolio">More information</Link></p>
            </div>
            <div className="col-lg-4">
              <Link to="/education">
                <img className="bd-placeholder-img rounded-circle" width="200" height="200" src="../assets/education.png" alt="Education section"></img>
              </Link>
              <h2 className="fw-normal">Education</h2>
              <p>In this section you can see my certified education</p>
              <p><Link className="btn btn-dark" to="/education">More information</Link></p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
