import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/index.css"

function NotFound() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div className="container">
                    <div className="text-center mt-3">
                        <h1>404 - Page Not Found</h1>
                    </div>
                    <div className="text-center mt-5">
                        <h6>The page you're looking for couldn't be found. Please check the URL or go back to the homepage.</h6>
                    </div>
                    <div className="mt-3 text-center">
                        <img className='rounded' src="./assets/404.jpg" width="650" alt="error-404" />
                    </div>
                </div>
            </main>
        </div>
    );
}

export default NotFound;
