import React from 'react';
import { Link } from 'react-router-dom'

/* SideBar Menu */
function SideBar() {
    return (
        <div className="sidebar border border-right col-md-3 col-lg-2 p-0">
            <div className="offcanvas-lg offcanvas-end" tabindex="-1" id="sidebarMenu"
                aria-labelledby="sidebarMenuLabel">
                <div class="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/">
                                <i className="bi bi-house"></i>
                                Ivette Jael Nobiltá
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/about">
                                <i className="bi bi-person-badge"></i>
                                About me
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/portfolio">
                                <i className="bi bi-pc-display-horizontal"></i>
                                Portfolio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/education">
                                <i className="bi bi-mortarboard"></i>
                                Education
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/contact">
                                <i className="bi bi-person-lines-fill"></i>
                                Contact
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/Articles">
                                <i className="bi bi-pencil-square"></i>
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/login">
                                <i className="bi bi-door-open"></i>
                                Login
                            </Link>
                        </li>
                    </ul>
                    <hr />

                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                        <span>Projects</span>
                    </h6>
                    <ul className="nav flex-column mb-auto">
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/mercadoLibro">
                                <i className="bi bi-file-code-fill"></i>
                                Mercado Libro
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/amazingEvents">
                                <i className="bi bi-file-code-fill"></i>
                                Amazing Events
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/inventoryManagementSystem">
                                <i className="bi bi-file-code-fill"></i>
                                Inventory Management System
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link class="nav-link d-flex align-items-center gap-2" to="/kanbanBoard">
                                <i className="bi bi-file-code-fill"></i>
                                KanbanBoard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link class="nav-link d-flex align-items-center gap-2" to="comingSoon">
                                <i className="bi bi-file-code-fill"></i>
                                Coming soon...
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SideBar;
