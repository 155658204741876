import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/index.css"

function Contact() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Contact</h1>
                </div>

                <div className="container contact mt-4">
                    <div className="text-center">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54664.56648560665!2d-64.53817472868265!3d-31.09484784909773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d82540a7563cd%3A0x63c72e7d55a0ee1e!2sLa%20Falda%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1701385796722!5m2!1ses!2sar"
                            width="600" height="350" allowFullScreen="" title="Google Maps" loading="lazy"></iframe>
                    </div>
                    <br />
                    <div className="row text-center">
                        <div className="col-lg-3">
                            <p>
                                <Link className="btn btn-dark" to="mailto:ijnobilta@gmail.com">
                                    <i className="bi bi-envelope-at" style={{ color: 'white' }}></i>
                                    <span style={{ verticalAlign: 'middle', padding: '3px' }}>Email me</span>
                                </Link>
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <p><Link className="btn btn-dark" to="https://www.instagram.com/ivettenobilta/" target="_blank" rel="noopener noreferrer">
                                <i class="bi bi-instagram" style={{ color: 'white' }}></i>
                                <span style={{ verticalAlign: 'middle', padding: '3px' }}>Instagram</span></Link></p>
                        </div>
                        <div className="col-lg-3">
                            <p><Link className="btn btn-dark" to="https://api.whatsapp.com/send?phone=3548607940" target="_blank" rel="noopener noreferrer">
                                <i class="bi bi-whatsapp" style={{ color: 'white' }}></i>
                                <span style={{ verticalAlign: 'middle', padding: '3px' }}>Whatsapp</span></Link></p>
                        </div>
                        <div className="col-lg-3">
                            <p><Link className="btn btn-dark" to="https://github.com/IvetteJN" target="_blank" rel="noopener noreferrer">
                                <i class="bi bi-github" style={{ color: 'white' }}></i>
                                <span style={{ verticalAlign: 'middle', padding: '3px' }}>GitHub</span></Link></p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Contact;
