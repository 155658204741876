import React from 'react';
import { Link } from 'react-router-dom';


/* Burger NavBar */
function Navbar() {
  return (
    <header className="navbar sticky-top flex-md-nowrap p-0" data-bs-theme="dark">
      <Link to="/">
        <img src="./assets/logos/3.png" alt="LOGO IJN" width="120px" className="logo p-2"></img>
      </Link>
      <ul className="navbar-nav flex-row d-md-none">
        <li className="nav-item text-nowrap">
          <button className="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <i className="bi bi-list-stars"></i>
          </button>
        </li>
      </ul>
    </header>
  );
}

export default Navbar;