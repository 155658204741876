import React from 'react';
import "../styles/index.css"

function InventoryManagementSystem() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 class="h2">Inventory Management System Project</h1>
                </div>

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div id="carouselExample" class="carousel slide" data-bs-theme="dark">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="./assets/IMS/IMS-1.png" class="d-block w-100 border-frame" alt="Load" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-2.png" class="d-block w-100 border-frame"
                                            alt="Login" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-3.png" class="d-block w-100 border-frame" alt="Menu" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-4.png" class="d-block w-100 border-frame"
                                            alt="Products" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-5.png" class="d-block w-100 border-frame"
                                            alt="Users" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-6.png" class="d-block w-100 border-frame"
                                            alt="Customers" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-7.png" class="d-block w-100 border-frame"
                                            alt="Categories" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/IMS/IMS-8.png" class="d-block w-100 border-frame"
                                            alt="Orders" />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}

export default InventoryManagementSystem;
