import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "../styles/index.css";
import "../styles/portfolio.css";

function Portfolio() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios.get('https://portfolio-ijn-backend.onrender.com/api/portfolio/')
            //axios.get('http://localhost:8000/api/portfolio/')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the portfolio data!", error);
            });
    }, []);

    return (
        <div>
            <main>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
                    <h1 className="h2">Portfolio</h1>
                </div>

                <div className="container marketing mt-4">
                    <div className="row text-center">
                        {projects.map(project => (
                            <div key={project.id} className="project">
                                <div className="contenedor">
                                    <img src={project.image} alt={project.title} className="image" />
                                    <div className="info-project">
                                        <h4 className="fw-normal">{project.title}</h4>
                                        <p>{project.description}</p>
                                        <p><Link className="btn btn-dark" to={project.project_link} target="_blank">See more</Link></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Portfolio;
