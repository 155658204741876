import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './NavBar';
import Sidebar from './SideBar';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Education from './Education';
import Login from './Login';
import MercadoLibro from './MercadoLibro';
import AmazingEvents from './AmazingEvents';
import InventoryManagementSystem from './InventoryManagementSystem';
import KanbanBoard from './KanbanBoard';
import ComingSoon from './ComingSoon';
import Articles from './Articles';
import Contact from './Contact';
import NotFound from './NotFound';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div>
        <Navbar />
        <div className="container-fluid" data-bs-theme="dark">
          <div className="row">
            <Sidebar />
            <div className="col-md-9 ms-sm-auto col-lg-10">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/about" element={<About isLoggedIn={isLoggedIn} />} />
                <Route path="/portfolio" element={<Portfolio isLoggedIn={isLoggedIn} />} />
                <Route path="/education" element={<Education isLoggedIn={isLoggedIn} />} />
                <Route path="/login" element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                <Route path="/mercadoLibro" element={<MercadoLibro isLoggedIn={isLoggedIn} />} />
                <Route path="/amazingEvents" element={<AmazingEvents isLoggedIn={isLoggedIn} />} />
                <Route path="/inventoryManagementSystem" element={<InventoryManagementSystem isLoggedIn={isLoggedIn} />} />
                <Route path="/kanbanBoard" element={<KanbanBoard isLoggedIn={isLoggedIn} />} />
                <Route path="/comingSoon" element={<ComingSoon isLoggedIn={isLoggedIn} />} />
                <Route path="/Articles" element={<Articles isLoggedIn={isLoggedIn} />} />
                <Route path="/contact" element={<Contact isLoggedIn={isLoggedIn} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
