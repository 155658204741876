import React from 'react';
import "../styles/index.css"

function AmazingEvents() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 class="h2">Amazing Events Project</h1>
                </div>

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div id="carouselExample" class="carousel slide" data-bs-theme="dark">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="./assets/AmazingEvents/AE-1.png" class="d-block w-100 border-frame"
                                            alt="Home" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/AmazingEvents/AE-2.png" class="d-block w-100 border-frame"
                                            alt="UpcomingEvents" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/AmazingEvents/AE-3.png" class="d-block w-100 border-frame"
                                            alt="UpcomingEventsFiltered" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/AmazingEvents/AE-4.png" class="d-block w-100 border-frame"
                                            alt="UpcomingEventsFiltered&SearchActivated" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/AmazingEvents/AE-5.png" class="d-block w-100 border-frame"
                                            alt="PastEvents" />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="./assets/AmazingEvents/AE-6.png" class="d-block w-100 border-frame"
                                            alt="Contact" />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                                    data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}

export default AmazingEvents;
