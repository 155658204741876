import React, { useState, useEffect } from 'react';
import "../styles/index.css";

function Articles() {
  const [articles, setArticles] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    fetch('./assets/articles.json')
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching articles:', error));
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      id="articlesContainer"
      className={`row m-3 ${isHovered ? 'blurred' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {articles.map(article => (
        <div key={article.id} className="col-md-6 mb-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">{article.title}</h5>
            </div>
            <img className="articleImg" src={article.img} alt={article.title} />
            <div className="card-body">
              <p>{article.snippet}</p>
              <button className="btn btn-light read-more">Read more</button>
              <div className="full-content d-none">
                <div>
                  <img className="m-3 rounded-circle" width="200" height="200" src={article.img} alt={article.title} />
                </div>
                <p className="card-text">{article.body}</p>
                <p className="card-date">Published on: {article.publishDate}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      {isHovered && (
        <div className="blur-overlay"></div>
      )}
      {isHovered && (
        <div className="construction-message">
          <h1>Section in construction</h1>
        </div>
      )}
    </div>
  );
}

export default Articles;
