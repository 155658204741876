import React from 'react';
import "../styles/index.css"

function KanbanBoard() {
    return (
        <div>
            {/* Content container */}
            <main>
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 class="h2">Kanban Board</h1>
                </div>

                <div class="container">
                    <div class="row justify-content-center col-lg-8">
                        <div class="col-lg-8">
                            <video width="900" controls>
                                <source src="./assets/KanbanBoard/KanbanVideo.mp4" type="video/mp4" />
                                Tu navegador no soporta el elemento de video.
                            </video>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default KanbanBoard;
