import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "../styles/index.css"

function About({ isLoggedIn }) {
  const [aboutMeData, setAboutMeData] = useState({
    intro: "",
    technologies: "",
    motivation: "",
    hobbies: ""
  });

  useEffect(() => {
    axios.get('https://portfolio-ijn-backend.onrender.com/api/aboutme/')
      //axios.get('http://localhost:8000/api/aboutme/')
      .then(response => {
        if (response.data.length > 0) {
          setAboutMeData(response.data[0]);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  if (!aboutMeData.intro && !aboutMeData.technologies && !aboutMeData.motivation && !aboutMeData.hobbies) {
    return <div>Please be patient, it's loading.</div>;
  }

  return (
    <div>
      {/* content container */}
      <main>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">About me</h1>
          {/* Agregar botones de edición/eliminación/agregación solo si está autenticado */}
          {isLoggedIn && (
            <div className="btn-group">
              <Link to="/edit/about" className="btn btn-primary">Editar</Link>
              <button className="btn btn-danger">Eliminar</button>
              <Link to="/add/about" className="btn btn-success">Agregar</Link>
            </div>
          )}
        </div>
        <section className="about-me p-3">
          <div>
            <p>{aboutMeData.intro}</p>
          </div>

          <div>
            <p>As a developer, I am proficient in a variety of technologies, including:</p>
            <ul>
              {aboutMeData.technologies.split('\r\n').map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>
          </div>

          <div>
            <p>{aboutMeData.motivation}</p>
          </div>

          <div>
            <p>{aboutMeData.hobbies}</p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default About;
